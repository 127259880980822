@import '_vars.scss';

.accounts-search {
	padding: 15px;
}

.accounts-results {
	flex-grow: 1;
	height: calc(100vh - 263px);
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	a {
		position: relative;
		padding: 10px;
		padding-top: 7px;
		padding-bottom: 7px;
		color: #000;
		text-decoration: none;
		.ico {
			position: relative;
			top: 4px;
			margin-right: 5px;
		}
		&:nth-child(odd) {
			background-color: #f4f4f4;
		}
		&:hover {
			background-color: lightgrey;
		}
	}
}

.account-content {
	height: 100%;
	.user-badges {
		padding-right: 15px;
		padding-left: 15px;
		font-size: 0px;
		svg {
			margin-left: 5px;
			margin-right: 5px;
		}
	}
	.account-content-header {
		border-bottom: 1px solid #EFEFEF;
		background-color: #f4f4f4;
		font-size: 25px;
		font-family: "robotocondensedlight";
		color: #3F3F3F;
		display: flex;
		height: 71px;
		padding-left: 20px;
		padding-right: 20px;
		align-items: center;
		span {
			flex-grow: 1;
		}
		&.user-deactivated {
			background-color: $red-02;
		}
	}
	.account-content-body {
		height: calc(100vh - 241px - 60px);
		overflow-y: auto;
		padding: 20px;
	}
	.account-content-footer {
		padding: 0 20px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #EFEFEF;
		background-color: #f4f4f4;

		>*:only-child {
			margin-left: auto;
		}
	}
}

.team-chooser {
	display: flex;
	align-items: center;
	span {
		margin-right: 5px;
	}
}

.accounts_company-list {
	display: flex;
	flex-direction: column;
}
.accounts_loader {
	position: absolute;
	right: 35px;
	top: 35px;
}

.package-list {
	ul {
		margin-top: 10px;
		li {
			list-style: circle inside;
		}
	}
}