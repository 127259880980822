@import '_vars.scss';

/** ADD NEW x */
.add-profile.add-profile--brand {
	.field--fullName {
		width: 100%;
		padding-right: 0px;
	}
}
.add-profile.add-profile--collective {
	.field--fullName {
		width: 100%;
		padding-right: 0px;
	}
}

/* ALL TABS */
.field--fullName, .field--stageName, .field--firstName, .field--lastName {
	width: 50%;
	display: inline-block;
}

.field--phone, .field--email {
	width: 50%;
	display: inline-block;
}

.field--fullName, .field--phone, .field--firstName, .field--stageName {
	padding-right: 30px;
}

/* TAB DEFAULT */
.tab--default {
	.field--gender, .field--birthdate, .field--nationalities {
		width: 50%;
		display: inline-block;
		vertical-align: top;
	}

	.field--activities {
		width: 33%;
		display: inline-block;
	}

	.field--birthdate {
		text-align: center;
		label {
			text-align: left;
		}
	}
	.field--socialNetworks {
		width: calc(100% / 3 * 2);
		display: inline-block;
		vertical-align: top;
	}
}

/* TAB INFLUENCE */
.tab--influence {
	.field--gender, .field--themesInfluence, .field--activities {
		width: calc(100% / 3);
		display: inline-block;
		vertical-align: top;
	}
	.field--influencingCountries, .field--influencingCountriesAuto {
		width: calc(100% / 2);
		display: inline-block;
		vertical-align: top;
	}
}

.profile--collectives {
	.tab--default {
		.field--fullName {
			display: block;
			width: 100%;
		}
	}
}


/* Discovery */
.profile.profile--discovery {
	.tab--influence {
		.field--influencingCountries, .field--birthdate, .field--nationalities {
			display: inline-block;
			width: 50%;
			vertical-align: top;
		}
		.field--birthdate {
			width: calc(100% / 3);
		}
		.field--socialNetworks, .field--birthdate {
			padding-right: 30px;
		}
		/* COLLECTIVE */
		&.tab--collective {
			.field--fullName {
				width: 100%;
				display: block;
			}
			.field--activities, .field--themesInfluence {
				width: 50%;
			}
		}
		.field--socialNetworksSuggestions, .field--profilesSuggestions {
			display: inline-flex;
			width: 50%;

			>div {
				width: 100%;
			}

			.snas-list {
				display: block !important;
			}
		} 

	}
	.field--addresses--birth {
		&>div:last-child {
			margin-top: 0px !important;
		}
		.field--addresses--birth--select {
			display: none;
		}
	}
	.field--addresses--living {
		&>div:last-child {
			margin-top: 0px !important;
		}
		.field--addresses--living--select {
			display: none;
		}
	}
	.field--addresses--heart {
		&>div:last-child {
			margin-top: 0px !important;
		}
		.field--addresses--heart--select {
			display: none;
		}
	}
}


@media screen and (max-width:1366px) {
	.tab--default .field--gender, .tab--default .field--birthdate, .tab--default .field--activities {
		width: 50%;
	}
	.tab--default .field--nationalities {
		width: 100%;
	}

	.tab--influence .field--gender, .tab--influence .field--activities {
		width: 50%;
	}
	.tab--influence .field--themesInfluence {
		width: 100%;
	}
}
