@import '_vars.scss';

.giftbox {
    .content-header {
        button {
            cursor: pointer;
            background: none;
            border: none;
            font-family: $f-light;
            margin-left: 10px;
            padding: 10px;
            border-radius: 4px;
            border: 1px solid #3F3F3F;
            min-width: 80px;
            opacity: 0.3;
            transition: all 0.2s linear;
            outline: none;
            &:hover {
                opacity: 1;
            }
            &.active {
                background-color: #3F3F3F;
                color: #fff;
                opacity: 1;
            }
        }
    }

    .features-and-packages {
        display: flex;
        > * {
            flex: 3;
            &:first-child {
                flex: 2;
            }
        }
    }

    .features-container {
        width: 50%;
        padding: 20px;
        .title-container {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #EFEFEF;
            h4 {
                padding: 0px;
                margin: 0px;
                text-transform: uppercase;
                font-family: $f-light;
                font-size: 24px;
                color: #a3a3a3;
                flex-grow: 1;
            }
        }
        
        .features-list {
            display: flex;
            flex-direction: column;
        }
        .feature-item {
            display: flex;
            font-family: $f-light;
            align-items: center;
            border-bottom: 1px solid #EFEFEF;
            padding-bottom: 5px;
            margin-bottom: 5px;
            .id-container {
                position: relative;
                display: flex;
                min-width: 175px;
                justify-content: flex-end;
                align-items: center;
            }
            .id {
                position: relative;
                background-color: #3F3F3F;
                color: #fff;
                font-size: 10px;
                border-radius: 4px;
                padding: 5px;
                margin-right: 10px;
            }
            .badge {
                width: 10px;
                height: 10px;
                border-radius: 4px;
                margin-right: 10px;
                position: relative;
                &--enabled {
                    background-color:green;
                }
                &--disabled {
                    background-color:red;
                }
            }
            .count {
                position: relative;
                background-color: #EFEFEF;
                color: #3f3f3f;
                font-size: 10px;
                border-radius: 4px;
                padding: 5px;
                margin-right: 5px;
            }
            .people {
                font-size: 0px;
                margin-right: 10px;
            }
            .description {
                flex-grow: 1;
            }
            .toolbar {
                display: flex;
            }
        }
    }
}

.select-features-title {
    font-size: 18px;
    text-transform: uppercase;
    font-family: $f-light;
    margin-top: 20px;
    margin-bottom: 10px;
}

.select-features-container {
    & > div {
        & > div {
            flex-direction: row;
            label {
                width: calc(50% - 16px);
            }
        }
    }
}