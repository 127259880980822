$f-regular: 'robotocondensedregular';
$f-bold: 'robotocondensedbold';
$f-light: 'robotocondensedlight';
$f-r-regular: 'robotoregular';
$f-r-bold: 'robotobold';

$header-color: #3F3F3F;
$border-color: #EFEFEF;

$grey: #525252;
$darkgrey:  #3F3F3F;
$lightgrey: #a3a3a3;
$superlightgrey: #f4f4f4;
$disabled: #d1d1d1;

$lightred: #ffe5e5;

$red: #f93434;
$red-02: rgba(249,52,52,0.2);
$red-05: rgba(249,52,52,0.5);
$red-08: rgba(249,52,52,0.8);

$darkred: #8c0000;
$darkred-02: rgba(140,0,0, 0.2);
$darkred-05: rgba(140,0,0, 0.5);
$darkred-08: rgba(140,0,0, 0.8);

$green: #00e600;
$lightgreen: #e5ffe5;
$darkgreen: #018c01;
$darkgreen-02: rgba(1,140,1,0.2);
$darkgreen-05: rgba(1,140,1,0.5);
$darkgreen-08: rgba(1,140,1,0.8);

$blue: rgb(124,192,246);
$blue-02: rgba(124,192,246,0.2);
$blue-05: rgba(124,192,246,0.5);
$blue-08: rgba(124,192,246,0.8);

$darkorange: #FF7900;
$orange: #FF9800;
$darkorange-05: rgba(255,121,0, 0.5);
$darkorange-08: rgba(255,121,0, 0.8);

$white: #fefefe;
$kolnavy: #003057;
$kolblue: #00ccff;
$kolgrey: #7F97B0;

$error-main: #f44336;
$error-dark: #ba000d;
$error-contrast: #ffebee;

$radius: 4px;
$mediumRadius: 8px;
$bigRadius: 12px;

$tinySpace: 4px;
$smallSpace: 8px;
$reducedSpace: 12px;
$space: 16px;
$increasedSpace: 24px;
$bigSpace: 32px;
