@import "css/scss/_vars.scss";

.title {
  font-size: 22px;
  margin: $tinySpace;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: $kolnavy;
  grid-gap: $space;

  > button {
    align-self: stretch;
    padding: 15px;
  }
}
.fields {
  width: 100%;
  display: grid;
  grid-gap: $space;
}
.askIfWantsToLogin {
  color: $kolgrey;
}
