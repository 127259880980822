@import '_vars.scss';
@import 'form.scss';

$fieldWidth: 200px;
$textAreaWidth: $fieldWidth * 2;

.inside-sales .content-body {
	height: calc(100vh - 170px);
	overflow-y: auto;
}

.content-header {
  .title { display: inline-block; }

  .create-qualif {
    display: inline-block;
    float: right;

    .create-button { padding-left: 5px; }
  }
}

.inside-sales_page {
	font-size: 14px;

  .dates-range-picker, .status-select, .admin-select, .reset-button {
    padding: 20px;
  }

  .admin-select {
    padding-right: 50px;
  }

  .reset-button {
    text-align: center;
    padding-right: 48px;
  }

  .status-input-label { margin-right: 10px; }

  .dates-range-picker {
    .DateRangePicker {
      margin-top: 5px;
    }
    input {
      font-size: 15px;
      padding: 10px 15px 6px;
    }
  }

  .search-results {
    th { color: #ffffff; }
    .listing {
      background-color: black;
      font-size: 14,
    }
    .status {
      text-align: center;
      text-transform: uppercase;
      padding: 2px 3px 2px 3px;
      border: 1px solid;
      border-radius: 3px;
    }
    .red {
      color: red;
      border-color: red;
    }
    .orange {
      color: orange;
      border-color: orange;
    }
    .green {
      color: green;
      border-color: green;
    }
    .blue {
      color: blue;
      border-color: blue;
    }
  }
}

.inside-sales_form {
	padding: 20px;

	.link-to-project {
		margin-bottom: 20px;

		a.btn-raised-blue {
			@extend button, .btn-raised-blue;
		}
	}

	fieldset {
		border: none;
		padding: 0;
		margin-bottom: 30px;
	}

  .form-section {
    display: flex;
    flex-wrap: wrap;
    width: 90vw;

    .form-item {
      width: 45vw;
    }
  }

	legend {
		font-family: $f-r-bold;
		font-size: 15px;
		margin-bottom: 15px;
		padding: 0;
	}

	.field {
		margin-bottom: 20px;
		width: $fieldWidth;

		&.field--large {
			width: $fieldWidth * 2;
		}
	}

	.formControl {
		min-width: $fieldWidth;
	}

	.field--inline {
		display: inline-block;
		width: 45%;
		padding-right: 30px;
	}

	button {
		margin-right: 15px;
	}
}

.date-pickers {
	display: flex;
	margin-bottom: 20px;

	.picker { margin-right: 20px; }
}

.listing-pager {
  display: flex;
  justify-content: center;
}

.dialog-actions {
  justify-content: center !important;

  .dialog-buttons {
    font-size: 1.2rem;
  }
}
