.bc-pagination {
  display: flex;
  flex-direction: row;
  button {
	  outline: none;
	  transition: color 0.2s linear, background-color 0.2s linear, border-color 0.2s linear;
	  svg {
		  path {
			  transition: fill 0.2s linear;
			  fill: #99acbc;
		  }
	  }
	  &:hover:not(:disabled) {
		  color: #fff;
	      background-color: #7fe5ff;
	      border-color: #7fe5ff;
		  svg {
			  path {
			  	fill: #fff;
		  	}
		  }
	  }
  }
}

.bc-pagination__next {
  height: 36px;
  width: 36px;
  cursor: pointer;
  align-self: center;

  border: 1px solid #e5eaee;
  border-radius: 4px;
  background-color: #FFFFFF;

  &:first-of-type {
    margin-right: 5px;
  }

  &:last-of-type {
    margin-left: 5px;
  }

  &:disabled {
	cursor: default;
	svg {
		path {
			fill: #ccd6dd !important;
		}
	}
  }

  svg {
    position: relative;
    top: 2px;
    right: 2px;
    width: 17px;
    height: 17px;
  }

  &-forward {
    transform: rotate(180deg);

    svg {
      position: relative;
      bottom: 2px;
      right: 2px;
      top: 0;
    }
  }
}

.bc-pagination__page {
  height: 36px;
  width: 36px;
  cursor: pointer;

  color: #99acbc;
  font-family: 'D-DIN Exp', 'Roboto';
  font-weight: normal;
  border: 1px solid #e5eaee;
  background-color: #FFFFFF;

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:focus {
    z-index: 1;
  }

  &:disabled {
	  cursor: default;
	  color: #ccd6dd;
  }

  &--selected {
    color: #fff !important;
    background-color: #00ccff;
    border-color: #00ccff;
	font-weight: bold;
  }
}
