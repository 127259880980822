@import '_vars.scss';

.discovery-search-filters {
	padding: 20px;
}

.profile--discovery {
	.profile-progress {
		right: 0px;
		border-right: none;
	}
	.profile-tab {
		width: 100%;
	}
	h3.with-chip {
		top: -5px;
		position: relative;
	}
	.profile-picture {
		width: 260px;
		height: 260px;
	}
}

.qualify {
	font-family: $f-light;
	font-size: 20px;
	text-transform: uppercase;
	text-align: center;
	padding-top: 20px;
}

.check-entity-exists {
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
	padding-top: 40px;
	border-top: 1px solid $superlightgrey;
	.modal-content-footer {
		padding: 20px;
		text-align: right;
	}
}

.profile-tab-content {
	width: 100%;
	.ptc-qualify {
		width: calc(100% - 200px);
		display: inline-block;
		vertical-align: middle;
	}
	.ptc-picture {
		width: 200px;
		height: 200px;
		display: inline-block;
		vertical-align: top;
		overflow: hidden;
		position: relative;
		&>img {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: auto;
			transform: translate(-50%, -50%);
		}
	}
}

.congrats {
	height: calc(100vh - 241px);
	text-align: center;
	&>div {
		position: relative;
		top: 50%;
		transform: translateY(-100%);
		p {
			font-size: 22px;
			font-family: $f-light;
			padding-left: 80px;
			padding-right: 80px;
			padding-top: 20px;
		}
		span {
			height: 10px;
			width: 200px;
			display: block;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				left: 0px;
				top: 0px;
				background-color: $blue-05;
				width: 100%;
				height: 100%;
				animation: reverse-progress-bar 3s linear;
				animation-fill-mode: forwards;
			}
		}
	}
	.congrats-graphic {
		width: 298px;
		height: 207px;
		position: relative;
		display: block;
		margin-left: auto;
		margin-right: auto;
		&>div {
			position: absolute;
			transform-origin: center;
			animation: stars-anim 0.5s linear infinite;
		}
		.cg-star-tl {
			top: 35px;
			left: 55px;
			animation-duration: 1s;
		}
		.cg-star-tr {
			top: 27px;
			right: 50px;
			animation-duration: 2.5s;
		}
		.cg-star-bl {
			bottom: 50px;
			left: 35px;
			animation-duration: 1.5s;
		}
		.cg-star-br {
			bottom: 35px;
			right: 48px;
			animation-duration: 2s;
		}
		.cg-thumb {
			left: 108px;
			top: 50px;
			transform-origin: bottom;
			animation: thumb-anim 0.5s linear infinite;
			animation-direction: alternate;
		}
	}
}

.other-choice {
	margin-left: 200px;
	display: block;
	text-align: center;
}

.discovery-other-value {
	width: 200px;
	margin-right: 20px !important;
}

@keyframes reverse-progress-bar {
    from { width: 100% }
    to { width: 0px }
}

@keyframes thumb-anim {
	from { transform: rotate(-10deg) }
	to { transform: rotate(10deg) }
}

@keyframes stars-anim {
	0% {
		transform: rotate(0deg) scale(0.6);
	}
	50% {
		transform: rotate(180deg) scale(1);
	}
	100% {
		transform: rotate(360deg) scale(0.6);
	}
}
