/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

::-webkit-input-placeholder {
	color: #99acbc;
}
::-moz-placeholder {
	color: #99acbc;
}
:-ms-input-placeholder {
	color: #99acbc;
}
:-moz-placeholder {
	color: #99acbc;
}

.bnc_field_input {
	display: block;
	position: relative;
	&.bnc_field_input--small {
		input {
			height: 30px;
			line-height: 30px;
		}
		&.bnc_field_input--has-label {
			input {
				padding-top: 0px
			}
		}
		&.bnc_field_input--shrink-label {
			label {
				&>span.label {
					top: -5px;
					left: 5px;
					-webkit-transform: translateY(-100%);
					-ms-transform: translateY(-100%);
					transform: translateY(-100%);
					font-size: 10px;
				}
			}
		}
	}
	&.bnc_field_input--label-as-option {
		label {
			&>span.label {
				color: #003057;
				font-family: 'D-DIN Exp', 'Roboto';
				font-size: 13px;
				font-weight: normal;
			}
		}
	}
	label {
		width: 100%;
		position: relative;
		display: block;
		&>span.label {
			position: absolute;
			z-index: 2;
			top: 50%;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			left: 16px;
			font-size: 14px;
			pointer-events: none;
			-webkit-transition: font-size 0.1s ease-out, top 0.1s ease-out, color 0.1s linear, left 0.1s ease-out, -webkit-transform 0.1s ease-out;
			transition: font-size 0.1s ease-out, top 0.1s ease-out, color 0.1s linear, left 0.1s ease-out, -webkit-transform 0.1s ease-out;
			-o-transition: font-size 0.1s ease-out, top 0.1s ease-out, color 0.1s linear, transform 0.1s ease-out, left 0.1s ease-out;
			transition: font-size 0.1s ease-out, top 0.1s ease-out, color 0.1s linear, transform 0.1s ease-out, left 0.1s ease-out;
			transition: font-size 0.1s ease-out, top 0.1s ease-out, color 0.1s linear, transform 0.1s ease-out, left 0.1s ease-out, -webkit-transform 0.1s ease-out;
			color: #99acbc;
			font-family: 'D-DIN Exp', 'Roboto';
			font-weight: bold;
			width: 100%;
		}
	}
	&.bnc_field_input--multilines {
		label {
			&>span.label {
				-webkit-transform: translateY(0);
				-ms-transform: translateY(0);
				transform: translateY(0);
				top: 16px;
			}
		}
	}
	&.bnc_field_input--shrink-label {
		label {
			&>span.label {
				top: 6px;
				-webkit-transform: translateY(0);
				-ms-transform: translateY(0);
				transform: translateY(0);
				font-size: 12px;
			}
		}
	}
	&.bnc_field_input--has-label {
		input {
			padding-top: 13px;
		}
		select {
			padding-top: 5px;
		}
		textarea {
			padding-top: 26px;
		}
	}
	&.bnc_field_input--disabled {
		label {
			&>span.label {
				color: #e5eaee;
			}
		}
	}
	&.bnc_field_input--error {
		label {
			&>span.label {
				color: #DD2727;
			}
		}
	}
	&.bnc_field_input--no-outline {
		input {
			&:focus {
				-webkit-box-shadow: none;
				box-shadow: none;
				border-color: #ccd6dd;
			}
		}
	}
	input, textarea, select {
		position: relative;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		-moz-appearance: none;
		appearance: none;
		-webkit-appearance: none;
		height: 56px;
		border: 1px solid #ccd6dd;
		border-radius: 4px;
		background-color: #FFFFFF;
		color: #003057;
		font-family: 'D-DIN Exp', 'Roboto';
		font-size: 13px;
		line-height: 56px;
		padding: 0px;
		padding-left: 16px;
		width: 100%;
		-webkit-box-shadow: 0 0 0 0px;
		box-shadow: 0 0 0 0px;
		&:focus {
			outline: none;
			-webkit-box-shadow: 0 0 0 1px #00ccff;
			box-shadow: 0 0 0 1px #00ccff;
			border-color: #00ccff;
		}
		&:disabled {
			border-color: #e5eaee;
			color: #99acbc;
		}
		&.--no-outline {
			&:focus {
				-webkit-box-shadow: none;
				box-shadow: none;
				border-color: transparent;
			}
		}
	}
	textarea {
		height: auto;
		line-height: 18px;
		padding-top: 11px;
		padding-bottom: 11px;
	}
	&.bnc_field_input--icon{
		label {
			&>span.label {
				padding-left: 26px;
			}
		}
		input {
			padding-left: 42px;
		}
	}
	&.bnc_field_input--icon-right {
		input, textarea {
			padding-right: 35px;
		}
	}
	.bnc_field_input_container {
		position: relative;
		z-index: 1;
	}

	.icon {
		width: 18px;
		height: 18px;
		pointer-events: none;
		fill: #003057;
		position: absolute;
		right: 12px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		z-index: 2;
		&--error {
			fill: #DD2727;
		}

		&--success {
			fill: #00E800;
		}
		&--left {
			right: initial;
			left: 11px;
		}
		&--rotate {
			-webkit-transition: -webkit-transform 0.2s ease-out;
			transition: -webkit-transform 0.2s ease-out;
			-o-transition: transform 0.2s ease-out;
			transition: transform 0.2s ease-out;
			transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
			-webkit-transform-origin: center;
			-ms-transform-origin: center;
			transform-origin: center;
			&--180 {
				-webkit-transform: rotate(180deg);
				-ms-transform: rotate(180deg);
				transform: rotate(180deg);
			}
		}
	}

	.help-text {
		position: absolute;
		right: 0px;
		font-size: 11px;
		font-family: 'D-DIN Exp', 'Roboto';
		color: #003057;
		bottom: 0px;
		-webkit-transform: translateY(100%);
		-ms-transform: translateY(100%);
		transform: translateY(100%);
		&--orange {
			color: orange;
		}
		&--red {
			color: #DD2727;
		}
	}

	span.error {
		display: block;
		color: #DD2727;
		font-family: 'D-DIN Exp', 'Roboto';
		font-size: 12px;
		padding-left: 11px;
		margin-top: 3px;
		font-weight: bold;
	}
}

.bnc_field_input_gmap {
  font-family: 'Roboto';
  width: 100%;
  position: relative;

  .bnc_field_input_gmap_predictions {
    position: absolute;
    border: solid 1px #DDDDDD;
    border-radius: 5px;
    width: calc(100% - 1px);
    display: block;
    background-color: #FFFFFF;
    margin-top: 2px;
    z-index: 999;


    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: block;
    }

    button {
      width: 100%;
      height: 100%;
      background: none;
      border: none;
      cursor: pointer;
      text-align: left;
      padding: 10px;
      &:hover {
        background: #eeeeee;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .active {
    background: #00ccff !important;
    color: #FAFAFA;
  }

}
