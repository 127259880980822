@import '_vars.scss';

@font-face {
    font-family: $f-bold;
    src: url('../../fonts/roboto-condensed-bold.woff2') format('woff2'),
         url('../../fonts/roboto-condensed-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $f-light;
    src: url('../../fonts/roboto-condensed-light.woff2') format('woff2'),
         url('../../fonts/roboto-condensed-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $f-r-regular;
    src: url('../../fonts/roboto-regular.woff2') format('woff2'),
         url('../../fonts/roboto-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $f-r-bold;
    src: url('../../fonts/roboto-bold.woff2') format('woff2'),
         url('../../fonts/roboto-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
	box-sizing: border-box;
}

html {
	font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  background-color: #F7F7F7;
  font-family: $f-r-regular;
  font-size: 14px;
}

p {
	font-family: $f-r-regular;
}


.ico svg {
	width: 20px;
	height: 20px;
}

.ico.ico--medium svg {
	width: 30px;
	height: 30px;
}

.ico.ico--big svg {
	width: 40px;
	height: 40px;
}

.ico.ico--small svg {
	width: 14px;
	height: 14px;
}

.nowrap {
	white-space: nowrap;
}

.badge {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: block;
}

.badge.badge--small {
	width: 10px;
	height: 10px;
}

.badge.badge--medium {
	width: 15px;
	height: 15px;
}

.badge.badge--square {
	border-radius: 0px;
}

.badge.badge--deactivated {
	background-color: $red;
	color: #fff;
}

.badge.badge--activated {
	background-color: $darkgreen;
	color: #fff;
}

.badge.badge--pending {
	background-color: $darkorange;
	color: #fff;
}

.badge.badge-social {
    width: 50px;
    height: 50px;
    background-color: #fff;
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translate(-30%, -30%);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
    svg {
        position: absolute;
        width: 30px;
        height: 30px;
        left: 10px;
        top: 10px;
    }
}


/****
* MAIN
*/
.main-container {
	position: relative;
	width: 100%;
	background: #fff;
}

.main-container.is-modal {
	filter: blur(3px) grayscale(25%) brightness(40%);
	pointer-events: none;
}

/*****
 * HEADER
 */
header.header {
	position: relative;
	display: flex;
	width: 100%;
	height: 90px;
	background-color: $header-color;
    align-items: center;
    justify-content: space-between;

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		top: 100%;
		height: 45px;
		background-color: $header-color;
	}

	&.with-logo {
		display: flex;
	}
}

.env-indicator {
	position: absolute;
	top: 0px;
	left: 0px;
	height: 5px;
	width: 100%;
}

.env-indicator {
	&.env---dev {
		background-color: $blue;
	}
	&.env---lab {
		background-color: $darkorange;
	}
	&.env---staging {
		background-color: $darkgreen;
	}
	&.env---prod {
		background-color: $red;
	}
}

.user--57 {
	header.header {
		background-color: #E91E63;
		&:after {
			background-color: #E91E63;
		}
		.header-item {
			background-color: #E91E63;
			&.active {
				background-color: #EC407A;
			}
		}
	}
}

.header-logo {
	width: 140px;
    margin: 26px 48px;
    object-fit: cover;
}

.header-ul, .header-ul-li {
	margin: 0px;
	padding: 0px;
	list-style: none;
	display: flex;
}

.header-ul {
	margin-left: 30px;
	height: 100%;
	gap: 10px;
}

.header-ul-li {
	display: inline-block;
	height: 100%;
	min-width: 80px;

	&.button-menu {
		display: flex;
		align-items: center;
		button {
			color: #fff;
			svg {
				width: 1.2em;
				height: 1.2em;
			}
		}
	}
}

.header-item {
	position: relative;
	padding: 8px;
	top: 50%;
	display: block;
	text-align: center;
	transform: translateY(-50%);
	color: #fff;
	text-decoration: none;
	font-size: 13px;
	font-family: $f-light;
	border-radius: 5px;
	background-color: $header-color;
	margin-top: 5px;
	transition: background 0.2s linear;

	&:hover {
		background-color: $grey;
	}
}

.header-item.active, .header-item.current  {
	background-color: $grey;
}

.header-item.current {
	pointer-events: none;
}

.header-item.active:after, .header-item.current:after {
	content: '';
	width: 0px;
	height: 0px;
	position: absolute;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #fff;
	top: calc(100% + 8px);
	left: 50%;
	transform: translateX(-50%);
}

.header-item-ico {
	display: block;

	path {
		fill: #fff;
	}

	>span[class^="Icon-module"] {
		margin: 4px auto;
	}
}


/****
* MAIN
*/
main {
	position: relative;
	width: calc(100% - 40px);
	margin-left: auto;
	margin-right: auto;
	background-color: #fff;
	border: 1px solid $border-color;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	height: calc(100vh - 90px);
	overflow: hidden;
}

section {
	position: relative;
}

/*****
* CONTENT
*/
.content-header {
	padding: 20px;
	border-bottom: 1px solid $border-color;
	&.flex {
		align-items: center;
		h2 {
			flex-grow: 1;
		}
	}
}

.content-header h2 {
	padding: 0px;
	margin: 0px;
	text-transform: uppercase;
	font-family: $f-light;
	font-size: 32px;
	color: $lightgrey;
}

.content-body {
	position: relative;
	width: 100%;
	font-size: 0px;
	overflow: auto;
}

.content-body-side {
	width: 290px;
	background-color: $white;
	display: inline-block;
	vertical-align: top;
	border-right: 1px solid $border-color;
	height: calc(100vh - 90px - 80px);
	position: relative;
	font-size: 14px;
}

.content-body-playground {
	width: calc(100% - 290px);
	display: inline-block;
	height: calc(100vh - 90px - 80px);
	position: relative;
	font-size: 14px;
	&.--full {
		width: 100%;
	}
}

/****
* MODAL
*/
.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 50%;
	font-size: 15px;
	transform: translate(-50%, -50%);
}
.modal.modal--large {
	width: 75%;
}

.modal.modal--xlarge {
	width: 95%;
}

.modal.modal--small {
	width: 30%;
}

.modal-content:not(.not-as-modal) {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.35);
	&>div {
		padding: 20px;
	}
	.modal-content-header {
		border-bottom: 1px solid $border-color;
		font-size: 18px;
		text-transform: uppercase;
		font-family: $f-light;
		.modal-content-header-subTitle{
			text-transform: none;
			font-size: 14px;
		}
	}

	.modal-content-body {
		max-height: calc(100vh - 63px - 59px - 140px);
		color: $darkgrey;
		overflow: auto;
		position: relative;
	}
	&>div.modal-content-footer {
		background-color: $superlightgrey;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border-top: 1px solid $border-color;
		text-align: right;
		font-family: $f-light;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.modal-content-footer button {
		margin-left: 20px;
	}
}

.modal.modal--error {
	.modal-close {
		.ico svg path {
			fill: #fff;
		}
	}
	.modal-content {
		background-color: #fff;
	}
	.modal-content-header {
		background-color: #B71C1C;
		color: #fff;
		border-bottom: none;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.modal-content > div.modal-content-footer {
		background-color: $superlightgrey;
	}
}

button.modal-close {
	position: absolute;
	top: 2px;
	right: 2px;
	text-align: center;
	cursor: pointer;
	path {
		fill: $grey;
	}
}

.prel {
	position: relative;
}
.full-width {
	width: 100% !important;
}
.full-wh {
	width: 100%;
	height: 100%;
}
.full-height {
	height: 100%;
}
.mb10 {
	margin-bottom: 10px !important;
}
.mb20 {
	margin-bottom: 20px !important;
}
.mt5 {
    margin-top: 5px !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt20 {
    margin-top: 20px !important;
}
.mt40 {
	margin-top: 40px;
}
.mr5 {
    margin-right: 5px !important;
}
.mr10 {
    margin-right: 10px !important;
}
.ml10 {
    margin-left: 10px;
}
.mr20 {
    margin-right: 20px;
}
.ml20 {
    margin-left: 20px;
}
.w120 {
    width: 120px;
}
.w180 {
	width: 180px;
}
.h120 {
    height: 120px;
}

.block {
	display: block !important;
}

.iflex {
	display: inline-flex !important;
}

.flex {
	display: flex !important;
	flex-wrap: wrap;
	&.no-wrap {
		flex-wrap: nowrap;
	}
}

.flex1 {
	flex: 1
}

.padl20 {
	padding-left: 20px;
}

.fd-column {
	flex-direction: column;
}

.fd-row {
	flex-direction: row;
}

.as-c {
	align-self: center;
}

.vam {
	vertical-align: middle;
}

.ai-c {
	align-items: center;
}
.ai-s {
	align-items: start;
}
.ai-e {
	align-items: end;
}
.ai-st {
	align-items: stretch;
}

.jc-sb {
	justify-content: space-between;
}

.jc-sa {
	justify-content: space-around;
}

.jc-sa {
	justify-content: space-evenly;
}

.jc-st {
	justify-content: stretch;
}

.jc-c {
	justify-content: center;
}
.gap5 {
	gap: 5px;
}
.gap10 {
	gap: 10px;
}
.gap15 {
	gap: 15px;
}
.gap20 {
	gap: 20px;
}
.tac { text-align: center; }
.tal { text-align: left; }
.tar { text-align: right; }

hr.divider {
	margin: 0px;
	padding: 0px;
	border: none;
	height: 1px;
	padding-top: 10px;
	margin-bottom: 40px;
	border-bottom: 1px solid $superlightgrey;
}

/* MENUS */
.menu-label {
	margin-left: 10px;
	font-family: $f-light;
	text-transform: uppercase;
}

/* AVATARS */
li.item--red {
	.avatar {
		background-color: $red;
	}
	span:not(.menu-label){
		color: $red;
	}
}

.avatar {
	&.avatar--24 svg {
		width: 24px;
		height: 24px;
	}
	&.avatar--white {
		svg, path {
			fill: #fff;
		}
	}
}

li.item--blue {
	.avatar {
		background-color: $blue;
	}
	span:not(.menu-label){
		color: $blue;
	}
}

li.item--orange {
	.avatar {
		background-color: $orange;
	}
	span:not(.menu-label){
		color: $orange;
	}
}

li.item--pink {
	.avatar {
		background-color: #FF729F;
	}
	span:not(.menu-label){
		color: #FF729F;
	}
}

li.item--green {
	.avatar {
		background-color: #30E2BF;
	}
	span:not(.menu-label){
		color: #30E2BF;
	}
}

li.item--purple {
	.avatar {
		background-color: #DCCCFF;
	}
	span:not(.menu-label){
		color: #DCCCFF;
	}
}

/* BUTTONS */
.button--error {
	color: red !important;
}

/* MESSAGES */
.message {
	width: 500px;
	background-color: #F5F5F5;
	border-left: 5px solid #616161;
	color: #212121;
	padding: 20px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	margin-bottom: 10px;
	&.message--error {
		background-color: #FFCDD2;
		border-left-color: #D32F2F;
		color: #B71C1C;
	}
	&.message--success {
		background-color: #C8E6C9;
		border-left-color: #388E3C;
		color: #1B5E20;
	}
	&.message--info {
		background-color: #E1F5FE;
		border-left-color: #0288D1;
		color: #01579B;
	}
	&.message--warning {
		background-color: #FFF8E1;
		border-left-color: #FFA000;
		color: #FF6F00;
	}
}

.progress-modale {
	background-color: #fff;
	position: absolute;
	width: 100%;
	height: 5px;
	bottom: 0px;
	right: 0px;
	transition: width 50ms linear;
}

.form-group-row {
    label {
        &:last-child {
            margin-right: 0px;
        }
    }
}

/* BUTTONS */
.no-btn {
	border: none;
	padding: 0px;
	background-color: transparent;
	outline: none;
	font-size: inherit;
	font-family: inherit;
	text-decoration: inherit;
	text-align: inherit;
	text-transform: inherit;
	color: inherit;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
.no-btn:focus, .no-btn:active {
	outline: none;
}

.strikethrough {
	text-decoration: line-through;
}