@import '_vars.scss';

.catalog-search {
	padding: 20px;
	padding-right: 50px;
	padding-top: 5px;
}

.catalog-search-results {
	padding-left: 20px;
	overflow-y: auto;
	transform: translateZ(0);
	-webkit-overflow-scrolling: touch;
	ul, li {
		margin: 0px;
		padding: 0px;
		list-style: none;
	}
}

li.catalog-result-item {
	a, &>div {
		width: 100%;
		height: 60px;
		margin-bottom: 5px;
		position: relative;
		padding-left: 20px;
		padding-right: 20px;
		background: #fff;
		transition: background 0.2s linear;
		color: $darkgrey;
		font-family: $f-r-regular;
		display: block;
		will-change: background;
		text-decoration: none;
	}
	&.has-picture {
		a, &>div {
			padding-left: 70px;
		}
	}
	a > div {
		overflow-x: hidden;
    	text-overflow: ellipsis;
	}
	.result-item-toolbox {
		position: absolute;
		top: 50%;
		right: 7px;
		transform: translateY(-50%);
		button {
			margin-left: 5px;
			margin-right: 5px;
		}
	}
}

li.catalog-result-item:nth-child(odd) {
	a, &>div {
		background-color: $superlightgrey;
	}
}

li.catalog-result-item {
	a:hover {
		background-color: $lightgrey;
	}
}


.result-item-picture {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 60px;
	height: 100%;
	overflow: hidden;
}

.result-item-picture > img {
	position: absolute;
	width: 100%;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.result-item-right{
	display: flex;
	height: 100%;
}

.result-center-vertical {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.result-item-icons{
	align-items: center;
	width: 20px;
}

.result-item-name {
	flex: 1;
    height: inherit;
	font-size: 14px;
	width: 180px;

	>.profile-name-inline{
		margin: $space 0;
	}
}

/* On boarding */
.catalog-onboarding {
	font-size: 25px;
	font-family: $f-light;
	text-transform: uppercase;
	color: $lightgrey;
	padding: 120px;
	padding-top: 80px;
	text-align: center;
}

.catalog-onboarding a.btn {
	display: inline-block;
	font-size: 20px;
}

.catalog-search-total {
	background-color: $superlightgrey;
	border-top: 1px solid $lightgrey;
	padding: 10px;
	text-align: center;
	text-transform: uppercase;
	font-family: $f-light;
	span {
		font-family: $f-bold;
	}
	.bc-pagination {
		justify-content: center;
	}
}

/* FILTERS */
.button-search-filters {
	position: absolute;
	right: 0px;
	top: -60px;
}

.catalog-filters-recap {
	padding-right: 20px;
	padding-left: 15px;
	max-height: 250px;
	overflow: auto;
	.cfr-item {
		margin-left: 5px;
		margin-bottom: 5px;
	}
	&:last-child {
		margin-bottom: 15px;
	}
}

.filters-popover {
	width: 850px;
	.fp-header {
		padding: 20px;
		background-color: #EEEEEE;
		text-transform: uppercase;
		font-size: 20px;
		font-family: $f-light;
		border-bottom: 1px solid #9E9E9E;
		position: relative;
		.fp-reset {
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
		}
	}
	.fp-content {
		padding: 20px;
		background-color: #fff;
		max-height: calc(100vh - 220px - 65px);
		position: relative;
		overflow: auto;
	}
}

.catalog-search-choose {
	font-size: 12px;
	font-family: $f-light;
	padding: 20px 0;
	padding-bottom: 0px;
	button {
		font-size: 12px;
		font-family: $f-light;
		border: none;
		background: none;
		padding: 0px;
		margin: 0px;
		margin-left: 8px;
		outline: none;
		cursor: pointer;
		&:after {
			content: '|';
			padding-left: 8px;
		}
		&:last-child {
			color: $red;
			&:after {
				display: none;
			}
		}
		&:hover {
			text-decoration: underline
		}
		&.active {
			text-decoration: underline;
			font-family: $f-bold;
		}
	}
}

/* LAYOUT SEARCH */
div.field-filter--profileStatut, div.field-filter--influenceCompletion {
	margin-bottom: 0px !important;
}
