.canClose {
  padding: 10px 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.actionsContainer {
  margin: 15px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}
.qrCode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 168px;
  height: 168px;
  img {
    width: 100%;
    height: 100%;
  }
}
.inputRoot {
  margin-top: 10px !important;
}
.submit {
  position: relative;
  .buttonProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
}
.actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}