@import '_vars.scss';

.profile {
	position: relative;
}

.verified-icon {
	margin-right: 5px;
	svg {
		fill: $darkorange;
	}
	&.verified-icon--community {
		svg {
			fill: $darkgreen;
		}
	}
}

.tracked-icon {
	svg {
		fill: #7C007C;
	}
}

.profile-name {
	padding: 20px;
	display: block;
	width: 100%;
	border-bottom: 1px solid $border-color;
	background-color: $superlightgrey;
	position: relative;
	.profile-fa-icon {
	  margin: 0px 8px 3px 2px;
	  width: 16px;
	  fill: $red;
	  &.subscribed {
		fill: $green;
	  }
	}
}

.profile-name h3 {
	margin: 0px;
	padding: 0px;
	font-size: 25px;
	font-family: $f-light;
	color: $darkgrey;
	height: 30px;
}

.profile-tabs {
	width: 200px;
	display: inline-block;
	vertical-align: top;
	background-color: $white;
	height: calc(100vh - 241px);
	position: relative;
	z-index: 2;
	ul, li {
		margin: 0px;
		padding: 0px;
		list-style: none;
		display: block;
		width: 100%;
	}
}

.profile-deleted {
	font-family: $f-light;
	color: #888;
    font-size: 12px;
    padding-bottom: 5px;
    display: block;
}

button.tabs-item {
	width: calc(100% - 20px);
	margin-left: 20px;
	display: block;
	text-align: right;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: 30px;
	padding-left: 10px;
	cursor: pointer;
	font-size: 15px;
	text-transform: uppercase;
	font-family: $f-light;
	background-color: $superlightgrey;
	transition: all 0.2s linear;
}

button.tabs-item.active {
	background-color: $darkgrey;
	color: #fff;
	cursor: default;
	pointer-events: none;
}

button.tabs-item.active:after {
	right: -2px;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0px;
	width: 0px;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-right-color: #fff;
	border-width: 10px;
	margin-top: -10px;
}

button.tabs-item:not(.active):hover {
	background-color: $lightgrey;
}

.tabs-item span.badge {
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.profile-tab {
	width: calc(100% - 200px);
	display: inline-block;
	vertical-align: top;
	border-left: 1px solid $border-color;
	height: calc(100vh - 241px);
	position: relative;
}

.profile-tab-content {
	width: 100%;
	position: relative;
	display: inline-block;
	vertical-align: top;
	border-left: 1px solid $border-color;
	height: calc(100vh - 241px - 60px);
	overflow-y: auto;
	padding: 20px;
	background-color: #fff;
	&.up-height {
		height: calc(100vh - 241px);
	}
}

.profile-tab-content-footer {
	position: relative;
	width: 100%;
	height: 60px;
	border-top: 1px solid $border-color;
	background-color: $superlightgrey;
	text-align: right;
	padding-right: 10px;
}

.profile-tab-content-footer button {
	margin-top: 10px;
	font-size: 15px;
	display: inline-block;
	position: relative;
}

/* PRORESS */
.profile-progress {
	position: absolute;
	right: 65px;
	top: 0px;
	height: 100%;
	border-right: 1px solid #fff;
	display: flex;
	align-items: center;
	& > div {
		border-left: 1px solid #fff;
		height: 100%;
		& > div {
			top: 50%;
			position: relative;
			transform: translateY(-50%);
		}
	}
}

.profile-change-state-container {
	padding-left: 20px;
	padding-right: 20px;
	& > div > div > div > div {
		    min-width: 100px;
	}
	.label-only {
		text-align: center
	}
}

.state-icon {
	display: inline-block;
	border-radius: 50%;
	height: 10px;
	width: 10px;
	margin-right: 5px;
	&.state--qualified {
		background-color: #4CAF50;
	}
	&.state--unqualified {
		background-color: #FF9800;
	}
	&.state--rejected {
		background-color: #F44336;
	}
	&.state--blocked {
		background-color: #000;
	}
	&.state--closed {
		background-color: #FF1212;
	}
}

.profile-progress-item {
	position: relative;
	width: 150px;
	text-align: center;
	font-family: $f-light;
	text-transform: uppercase;
}

.progress-item-outline {
	width: 60%;
	height: 12px;
	margin-left: auto;
	margin-right: auto;
	background: none;
	margin-top: 5px;
	display: block;
	border-radius: 5px;
	border: 1px solid $border-color;
	position: relative;
	transition: border 0.2s linear;
}

.progress-item-bar {
	position: absolute;
	top: 0px;
	left: 0px;
	border-radius: 5px;
	width: 0%;
	height: 100%;
	transition: width 0.2s ease-out, background 0.2s linear;
	background-color: $border-color;
}

.progress-item-outline.progress-item--deactivated {
	border-color: $red;
	.progress-item-bar {
		background-color: $red-08;
	}
}
.progress-item-outline.progress-item--activated {
	border-color: $darkgreen;
	.progress-item-bar {
		background-color: $darkgreen-08;
	}
}
.progress-item-outline.progress-item--pending {
	border-color: $darkorange;
	.progress-item-bar {
		background-color: $darkorange-08;
	}
}

/* TOOLS */
.profile-tools {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.profile-delete {
	path {
		transition: fill 0.2s linear
	}
	&:hover path {
		fill: $darkred;
	}
}

/* MODALE */
.modale-profile-name {
	font-family: $f-r-bold;
}

/* TAB */
.access-switcher {
	padding-bottom: 20px;
	border-bottom: 1px solid $border-color;
	margin-bottom: 20px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	> label {
		text-transform: uppercase;
		font-family: $f-light;
		font-size: 20px;
	}
}
.field {
	position: relative;
	display: block;
	margin-bottom: 30px;
}

/* MODALE */
.add-new-sna-form {
	position: relative;
	display: flex;
	& > div {
		flex-grow: 1;
		padding-right: 110px;
	}
	.btn-add-new-sna-request {
		position: absolute;
		bottom: 18px;
		right: 0px;
	}
}
.add-new-sna-form_footer {
	display: flex;
	justify-content: space-between;

	.left-buttons {
		display: flex;
		button {
			margin-left: 0 !important;
		}
	}
	.right-buttons {
		display: flex;
	}
}

.add-new-sna-details {
	.sna-details-name {
		font-family: $f-bold;
		font-size: 18px;
	}
	.sna-details-community {
		span {
			font-family: $f-bold;
		}
	}
}

.add-profile-check .catalog-search-results {
	height: auto;
}

.add-profile-acceptance {
	border-top: 1px solid $superlightgrey;
	margin-top: 20px;
}


.tab-form--disabled {
	filter: blur(3px);
	opacity: 0.5;
	pointer-events: none;
}

.merged-profiles {
	.row {
		width: 100%;
		display: flex;
		align-items: stretch;
		.cell {
			width: calc(100% / 3);
			border-right: 1px solid $lightgrey;
			padding: 40px;
			&:last-child {
				border-right: none;
			}
		}
		.field--conflict {
			min-height: 70px;
			position: relative;
			&:after {
				content: "";
				position: absolute;
				border: 2px solid green;
				width: calc(100% - 40px);
				height: calc(100% - 40px);
				top: 20px;
				left: 20px;
				opacity: 0;
				transition: opacity 0.2s linear;
				pointer-events: none;
			}
			&.selected {
				&:after {
					opacity: 1;
				}
			}
			.field--conflict--field {
				padding-left: 60px;
				.multi-combo-select-text {
					display: block;
					&> div {
					   width: 90% !important;
					   margin-bottom: 40px;
				   }
				}
			}
			.field--conflict--check {
				position: absolute;
				top: 20px;
				left: 20px;
			}
		}
		&.merged-profile-header {
			border-bottom: 1px solid $lightgrey;
			position: relative;
			&>div {
				position: relative;
				height: 100%;
			}
			.cell {
				padding: 20px;
			}

			.merged-profile-header-picture {
				max-width: 100px;
				height: 100%;
				overflow: hidden;
				&>img {
					width: 100%;
					height: auto;
				}
			}
			.merged-profile-header-name h5 {
				margin: 0px;
				padding: 0px;
				margin-left: 20px;
				font-size: 20px;
				text-transform: uppercase;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}


.modale-confirm-change-state {
	.profile-name-inline, .profile-state-to {
		font-family: $f-bold;
		font-size: 18px;
	}
	.profile-state-to {
		margin-left: 10px;
	}
}

.snas-list {
	.sna-chip {
		position: relative;
		.sna-chip-badge {
			position: absolute;
			left: 0px;
			border-radius: 50%;
			background-color: #fff;
			border: 1px solid $lightgrey;
			width: 26px;
			height: 26px;
			text-align: center;
			svg {
				display: inline-block;
				position: relative;
				width: 18px;
				margin-left: auto;
				margin-right: auto;
				top: 50%;
				transform: translateY(-50%);
			}
			&.sna-chip-badge--connected {
				top: 0px;
				transform: translate(-20%, -20%);
				svg {
					fill: #FFB300;
				}
			}
			&.sna-chip-badge--spammer {
				cursor: pointer;
				top: 0px;
				transform: translate(-20%, -20%);
				svg {
					fill: $error-dark;
				}
				&.spammer_collected {
					svg {
						fill: $darkgreen;
					}
				}
			}
			&.sna-chip-badge--linked {
				bottom: 0px;
				transform: translate(-20%, 20%);
				cursor: pointer;
				svg {
					fill: #3949AB;
				}
			}
			&.sna-chip-badge--certified {
				margin: 0px;
				padding: 0px;
				bottom: 0px;
				transform: translate(-20%, 20%);
				outline: none;
				cursor: pointer;
				svg {
					transform: none;
					left: 1px;
					fill: $darkgreen;
					top: 0;
				}
			}
		}
	}
}

.modale-confirm-convert-to {
	.buttons {
		display: flex;
		justify-content: center;

		button {
			margin-left: 10px;
			margin-right: 10px;
			&.--selected {
				background-color: $green;
			}

			&.--current {
				background-color: $blue;
			}

		}
	}
	.message {
		margin-top: 20px;
	}
}
