.logs-listing {
	padding: 20px;
	font-size: 12px;
}

.logs {
	.content-body-playground {
		height: calc(100vh - 170px - 50px);
		overflow-y: auto;
	}
	.content-body-footer {
		position: relative;
		width: calc(100% - 290px);
		left: 290px;
		top: -50px;
		font-size: 12px;
		background: #f4f4f4;
		height: 50px;
		border-top: 1px solid #EFEFEF;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 20px;
		padding-left: 20px;
	}
	.content-header {
		display: flex;
		align-items: center;
		h2 {
			flex-grow: 1;
		}
	}
}

.logs-log {
	display: flex;
	padding: 8px;
	border-bottom: 1px solid #f4f4f4;
	align-items: center;
	.log-cell--admin {
		min-width: 20%;
		max-width: 20%;
		width: 20%;
	}
	.log-cell--date {
		min-width: 15%;
		max-width: 15%;
		width: 15%;
	}
	.log-cell--action {
		min-width: 20%;
		max-width: 20%;
		width: 20%;
	}
	.log-cell--details {
		min-width: 20%;
		max-width: 20%;
		width: 20%;
	}
	&.logs-log--header {
		font-weight: bold;
	}
	&:nth-child(even) {
		background-color: #f4f4f4;
	}
	&.logs-log--toreview {
		background-color: orange;
	}

	.log-cell--actions {
		flex-grow: 1;
		display: flex;
		justify-content: flex-end;
	}
}

.logs-popover-details {
	padding: 20px;
}

.log-details-line {
	display: flex;
	width: 800px;
	padding: 5px;
	&.log-details-line--header {
		border-bottom: 1px solid #f4f4f4;
		font-weight: bold;
	}
	&:nth-child(even) {
		background-color: #f4f4f4;
	}

	.log-details-line-cell--key {
		width: 20%;
		min-width: 20%;
		max-width: 20%;
	}
	.log-details-line-cell--from, .log-details-line-cell--to {
		width: 40%;
		min-width: 40%;
		max-width: 40%;
	}
}

.logs-stats-popover {
	padding: 20px;
	width: 1100px;
	.logs-stats-line {
		display: flex;
		padding: 5px;
		padding-top: 7px;
		padding-bottom: 7px;
		&:nth-child(even) {
			background-color: #f4f4f4;
		}
		&.logs-stats-line--header {
			font-weight: bold;
		}

		.logs-stats-cell--admin {
			flex-grow: 1;
		}

		.logs-stats-cell--stat {
			width: 10%;
			max-width: 10%;
			min-width: 10%;
			text-align: right;
		}

		.logs-stats-cell--total {
			width: 15%;
			max-width: 15%;
			min-width: 15%;
			font-weight: bold;
			text-align: right
		}
	}
}

.logs-filters {
	padding: 20px;
	.logs-filters-section {
		margin-bottom: 20px;
	}
}
