@import '_vars.scss';

::-webkit-input-placeholder {
  color: $lightgrey;
}
::-moz-placeholder {
  color: $lightgrey;
}
:-ms-input-placeholder {
  color: $lightgrey;
}
:-moz-placeholder {
  color: $lightgrey;
}

.search-input {
	width: 100%;
	position: relative;
	svg {
		width: 20px;
		height: auto;
		position: relative;
		top: 5px;
		path {
			fill: $lightgrey;
		}
	}
	input {
		width: calc(100% - 30px);
	}
}


.search-input .ico {
	position: absolute;
	cursor: pointer;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}


/* Buttons */
.no-btn {
	border: none;
	padding: 0px;
	background-color: transparent;
	outline: none;
	font-size: inherit;
	font-family: inherit;
	text-decoration: inherit;
	text-align: inherit;
	text-transform: inherit;
	color: inherit;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	font-family: $f-r-regular;
	position: relative;
	cursor: pointer;
}

.btn {
	padding: 15px;
	text-decoration: none;
	font-family: $f-light;
	font-size: 16px;
	text-transform: uppercase;
	text-decoration: none;
	border-radius: 5px;
	transition: all 0.2s linear;
	display: block;
	position: relative;
	text-align: center;
	white-space: nowrap;
	cursor: pointer;
	color: $darkgrey;
	background-color: #fff;
	border: 1px solid #fff;
}

button.btn {
	font-family: $f-light;
}

button.btn-flat-green {
	background-color: transparent;
	color: $darkgreen;
	&:hover {
		background-color: $darkgreen-02;
	}
}

button.btn-green {
	background-color: $darkgreen-08;
	color: #fff;
	&:hover {
		background-color: $darkgreen;
		color: #fff;
	}
}
button.btn-raised-green {
	background-color: $darkgreen-08;
	color: #fff;
	&:hover {
		background-color: $darkgreen;
		color: #fff;
	}
}
button.btn-raised-red {
	background-color: $red-08;
	color: #fff;
	&:hover {
		background-color: $red;
		color: #fff;
	}
}
button.btn-raised-blue {
	background-color: $blue-08;
	color: #fff;
	&:hover {
		background-color: $blue;
		color: #fff;
	}
}
button.btn-flat {
	font-family: $f-bold;
}
button.btn-flat-red {
	background-color: transparent;
	color: $red;
	&:hover {
		background-color: $red-02;
	}
}
button.btn-flat-blue {
	background-color: transparent;
	color: $blue;
	&:hover {
		background-color: $blue-02;
	}
}
button.btn-ico {
	path {
		transition: fill 0.2s linear;
	}
}
button.btn-ico.btn-ico--red {
	svg {
		fill: $red-08;
	}
	&:hover svg {
		fill : $red;
	}
	span:nth-child(2) {
		color: $red-05;
	}
}
button.btn-ico.btn-ico--green {
	svg {
		fill: $darkgreen-08;
	}
	&:hover svg {
		fill : $darkgreen;
	}
	span:nth-child(2) {
		color: $darkgreen-05;
	}
}

button:disabled {
	color: rgba(0, 0, 0, 0.26) !important;
}

/* INPUT */
.text-input-count {
	position: absolute;
	bottom: 20px;
	right: 20px;
	&.count--full {
		color: $red;
	}
	&.count--warning {
		color: $orange;
	}
}

/* Switcher */
input.switcher-checkbox {
	appearance: none;
	width: 30px;
	height: 15px;
	background-color: rgba(0,0,0,0.38);
	display: inline-block;
	vertical-align: middle;
	margin-right: 30px;
	border-radius: 15px;
	position: relative;
	cursor: pointer;
	outline: none;
	transition: background 0.2s linear;
}

input.switcher-checkbox:after {
	content: "";
	width: 20px;
	height: 20px;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 0px;
	transform: translate(-50%, -50%);
	background-color: #fafafa;
	box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.15);
	transition: background 0.2s linear, left 0.2s ease-out;
}

input.switcher-checkbox:checked {
	background-color: $darkgreen-05;
}

input.switcher-checkbox:checked:after {
	background-color: $darkgreen;
	left: 100%;
}

.switcher--orange input.switcher-checkbox:checked {
	background-color: $darkorange-05;
}

.switcher--orange input.switcher-checkbox:checked:after {
	background-color: $darkorange;
	left: 80%;
}

label.disabled, .disabled label {
	cursor: default;
}

div.radio-container {
	display: inline-block;
}

/* SELECT */
li.select-sticky {
	background-color: $white;
}
.select-search input {
	line-height: 30px;
}

/* Multilinagual */
.multilingual-input {
	display: block;
	position: relative;
}
.multilingual-input > label {
	display: block;
	margin-bottom: 10px;
}
.text-multi-tabs {
	position: absolute;
	display: inline-block;
	top: 24px;
	right: 0px;
	transform: translateY(-100%);
	ul {
		width: 100%;
	}
	ul, li {
		margin: 0px;
		padding: 0px;
		font-size: 1px;
	}
	overflow: hidden;
	vertical-align: top;
	button {
		padding: 5px;
		svg {
			width: 20px;
			height: auto;
		}
	}
	li {
		display: inline-block;
		text-align: center;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 8px;
		padding-right: 8px;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		&.active {
			background-color: #f5f5f5;
		}
	}
}

.text-multi-tab-container {
	width: 100%;
	vertical-align: top;
	display: inline-block;
	padding: 20px;
	background-color: #f5f5f5;
	position: relative;
}
/* DATE */
.datepicker-section {
	width: calc(100% / 3);
	position: relative;
	display: inline-block;
	vertical-align: top;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.datepicker-label {
	display: block;
}

.select-has-more {
	justify-content: flex-end !important;
}

.avatar-flag > img {
	height: 100%;
	width: auto;
}

/* MULTI SELECT */
.multi-select-selected-values {
	display: flex;
	flex-wrap: wrap;
	span {
		user-select: all !important;
	}
}

.multi-select-item {
	margin: 5px !important;
	.chip-social {
		transition: all 0.3s linear;

		// because CSS load order may be different depending on build tools
		width: 40px !important;
		height: 40px !important;
		margin-left: 0 !important;
	}
	&.item--certified {
		.chip-social {
			border: 1px solid $darkgreen;
			background-color: $lightgreen;
		}
	}
	&.item--himself {
		background-color: rgba(0, 204, 255, 0.3);
		.chip-social {
			border: 1px solid #00CCFF;
			background-color: rgba(0, 204, 255, 0.5);
		}
	}
}

.item-country {
	&>span {
		vertical-align: middle;
		display: inline-block;
	}
	.item-country-flag {
		position: relative;
		top: 3px;
		margin-right: 10px;
		&>img{
			width: 40px;
			height: auto;
		}
	}
}

.multi-select-add-value > div {
	display: inline-flex;
}
/* Chip */
div.chip-social {
    background: #fff;
    border: 1px solid rgb(185,185,185);
}
.chip-social--small {
	div.chip-social {
		width: 25px;
		height: 25px;
	}
	.ico svg {
		width: 12px;
		height: 12px;
	}
}

/* Adresses */
.multi-combo-select-text {
	display: flex;
	justify-content: space-between;
}

/* Label only */
span.label-only {
	color: #9e9e9e;
    padding: 0;
    font-family: $f-r-regular;
    line-height: 1;
	display: block;
	margin-bottom: 5px;
}


.label--line {
	display: flex;
	position: relative;
	align-items: flex-start;
	legend {
		color: #000 !important;
		margin-right: 10px;
		width: 85px;
		min-width: 85px;
		white-space: nowrap;
		top: 17px;
		position: relative;
	}
}
